import ServiceBase from "./service-base";

export class LightService extends ServiceBase {
    async getListLights(stationId) {
        try {
            const resp = await this.get(`/api/station/v1/${stationId}/lights`);
            return (resp?.data?.data?.lights || []).map((dto) => {
                return {
                    id: dto?.id,
                    latitude: dto?.latitude ?? 0,
                    longitude: dto?.longitude ?? 0,
                    serialNumber: dto?.serialNumber ?? "",
                    name: dto?.name ?? "",
                    dim: dto?.dim ?? 0,
                    state: dto?.state ?? false,
                }
            }).filter(e => e.latitude !== undefined && e.longitude !== undefined)
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async updateLightInfo(stationId, lightId, {
        lat,
        lng,
        name,
    }) {
        return (await this.put(`/api/station/v1/${stationId}/lights/${lightId}`, {
            latitude: lat,
            longitude: lng,
            name: name,
        })).data;
    }

    async updateLightState(stationId, lightId, {
        state,
        dim,
    }) {
        return (await this.post(`/api/station/v1/${stationId}/lights/${lightId}/control`, {
            state: state,
            dim: dim
        })).data;

    }

    async getLightDetail(stationId, lightId) {
        return (await this.get(`/api/station/v1/${stationId}/lights/${lightId}`)).data.data;
    }
}
