// source: user_proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_proto_pb = require('./common_proto_pb.js');
goog.object.extend(proto, common_proto_pb);
goog.exportSymbol('proto.AuthUserMessage', null, global);
goog.exportSymbol('proto.DeviceOnchangeUserMessage', null, global);
goog.exportSymbol('proto.ErrorStatusUserMessage', null, global);
goog.exportSymbol('proto.LogUpdateUserMessage', null, global);
goog.exportSymbol('proto.MeterDataUpdateUserMessage', null, global);
goog.exportSymbol('proto.StateOnChangeUserMessage', null, global);
goog.exportSymbol('proto.UpdateAllLightsStatusUserMessage', null, global);
goog.exportSymbol('proto.UpdateDeviceConnectionState', null, global);
goog.exportSymbol('proto.UpdateLightStatusUserMessage', null, global);
goog.exportSymbol('proto.UpdateScheduleCompletedUserMessage', null, global);
goog.exportSymbol('proto.UserLoginRequest', null, global);
goog.exportSymbol('proto.UserLoginResponse', null, global);
goog.exportSymbol('proto.UserMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserLoginRequest.displayName = 'proto.UserLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserLoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UserLoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserLoginResponse.displayName = 'proto.UserLoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AuthUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AuthUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AuthUserMessage.displayName = 'proto.AuthUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StateOnChangeUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StateOnChangeUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StateOnChangeUserMessage.displayName = 'proto.StateOnChangeUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MeterDataUpdateUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MeterDataUpdateUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MeterDataUpdateUserMessage.displayName = 'proto.MeterDataUpdateUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDeviceConnectionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateDeviceConnectionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDeviceConnectionState.displayName = 'proto.UpdateDeviceConnectionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LogUpdateUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LogUpdateUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LogUpdateUserMessage.displayName = 'proto.LogUpdateUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ErrorStatusUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ErrorStatusUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ErrorStatusUserMessage.displayName = 'proto.ErrorStatusUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateScheduleCompletedUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateScheduleCompletedUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateScheduleCompletedUserMessage.displayName = 'proto.UpdateScheduleCompletedUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateAllLightsStatusUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateAllLightsStatusUserMessage.repeatedFields_, null);
};
goog.inherits(proto.UpdateAllLightsStatusUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateAllLightsStatusUserMessage.displayName = 'proto.UpdateAllLightsStatusUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateLightStatusUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateLightStatusUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateLightStatusUserMessage.displayName = 'proto.UpdateLightStatusUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceOnchangeUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceOnchangeUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceOnchangeUserMessage.displayName = 'proto.DeviceOnchangeUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserMessage.displayName = 'proto.UserMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UserLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
assignedstationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
isweb: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserLoginRequest}
 */
proto.UserLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserLoginRequest;
  return proto.UserLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserLoginRequest}
 */
proto.UserLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedstationid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsweb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssignedstationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsweb();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.UserLoginRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assignedStationId = 2;
 * @return {string}
 */
proto.UserLoginRequest.prototype.getAssignedstationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setAssignedstationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isWeb = 3;
 * @return {boolean}
 */
proto.UserLoginRequest.prototype.getIsweb = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setIsweb = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserLoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UserLoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserLoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
projectid: jspb.Message.getFieldWithDefault(msg, 1, ""),
userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
role: jspb.Message.getFieldWithDefault(msg, 3, 0),
mapkey: jspb.Message.getFieldWithDefault(msg, 4, ""),
email: jspb.Message.getFieldWithDefault(msg, 5, ""),
phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
fullname: jspb.Message.getFieldWithDefault(msg, 7, ""),
statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserLoginResponse}
 */
proto.UserLoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserLoginResponse;
  return proto.UserLoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserLoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserLoginResponse}
 */
proto.UserLoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapkey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserLoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserLoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserLoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMapkey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 role = 3;
 * @return {number}
 */
proto.UserLoginResponse.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string mapKey = 4;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getMapkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setMapkey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fullname = 7;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UserLoginResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UserLoginResponse} returns this
*/
proto.UserLoginResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserLoginResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AuthUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.AuthUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AuthUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AuthUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
userloginrequest: (f = msg.getUserloginrequest()) && proto.UserLoginRequest.toObject(includeInstance, f),
userloginresponse: (f = msg.getUserloginresponse()) && proto.UserLoginResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AuthUserMessage}
 */
proto.AuthUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AuthUserMessage;
  return proto.AuthUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AuthUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AuthUserMessage}
 */
proto.AuthUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserLoginRequest;
      reader.readMessage(value,proto.UserLoginRequest.deserializeBinaryFromReader);
      msg.setUserloginrequest(value);
      break;
    case 2:
      var value = new proto.UserLoginResponse;
      reader.readMessage(value,proto.UserLoginResponse.deserializeBinaryFromReader);
      msg.setUserloginresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AuthUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AuthUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AuthUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AuthUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserloginrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserLoginRequest.serializeBinaryToWriter
    );
  }
  f = message.getUserloginresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserLoginResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserLoginRequest userLoginRequest = 1;
 * @return {?proto.UserLoginRequest}
 */
proto.AuthUserMessage.prototype.getUserloginrequest = function() {
  return /** @type{?proto.UserLoginRequest} */ (
    jspb.Message.getWrapperField(this, proto.UserLoginRequest, 1));
};


/**
 * @param {?proto.UserLoginRequest|undefined} value
 * @return {!proto.AuthUserMessage} returns this
*/
proto.AuthUserMessage.prototype.setUserloginrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AuthUserMessage} returns this
 */
proto.AuthUserMessage.prototype.clearUserloginrequest = function() {
  return this.setUserloginrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AuthUserMessage.prototype.hasUserloginrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserLoginResponse userLoginResponse = 2;
 * @return {?proto.UserLoginResponse}
 */
proto.AuthUserMessage.prototype.getUserloginresponse = function() {
  return /** @type{?proto.UserLoginResponse} */ (
    jspb.Message.getWrapperField(this, proto.UserLoginResponse, 2));
};


/**
 * @param {?proto.UserLoginResponse|undefined} value
 * @return {!proto.AuthUserMessage} returns this
*/
proto.AuthUserMessage.prototype.setUserloginresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AuthUserMessage} returns this
 */
proto.AuthUserMessage.prototype.clearUserloginresponse = function() {
  return this.setUserloginresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AuthUserMessage.prototype.hasUserloginresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StateOnChangeUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.StateOnChangeUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StateOnChangeUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
devicestate: (f = msg.getDevicestate()) && common_proto_pb.DeviceState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StateOnChangeUserMessage}
 */
proto.StateOnChangeUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StateOnChangeUserMessage;
  return proto.StateOnChangeUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StateOnChangeUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StateOnChangeUserMessage}
 */
proto.StateOnChangeUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.DeviceState;
      reader.readMessage(value,common_proto_pb.DeviceState.deserializeBinaryFromReader);
      msg.setDevicestate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StateOnChangeUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StateOnChangeUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StateOnChangeUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicestate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.DeviceState.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.StateOnChangeUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StateOnChangeUserMessage} returns this
 */
proto.StateOnChangeUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceState deviceState = 2;
 * @return {?proto.DeviceState}
 */
proto.StateOnChangeUserMessage.prototype.getDevicestate = function() {
  return /** @type{?proto.DeviceState} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DeviceState, 2));
};


/**
 * @param {?proto.DeviceState|undefined} value
 * @return {!proto.StateOnChangeUserMessage} returns this
*/
proto.StateOnChangeUserMessage.prototype.setDevicestate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StateOnChangeUserMessage} returns this
 */
proto.StateOnChangeUserMessage.prototype.clearDevicestate = function() {
  return this.setDevicestate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StateOnChangeUserMessage.prototype.hasDevicestate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MeterDataUpdateUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.MeterDataUpdateUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MeterDataUpdateUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MeterDataUpdateUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
meterdata: (f = msg.getMeterdata()) && common_proto_pb.MeterData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MeterDataUpdateUserMessage}
 */
proto.MeterDataUpdateUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MeterDataUpdateUserMessage;
  return proto.MeterDataUpdateUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MeterDataUpdateUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MeterDataUpdateUserMessage}
 */
proto.MeterDataUpdateUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.MeterData;
      reader.readMessage(value,common_proto_pb.MeterData.deserializeBinaryFromReader);
      msg.setMeterdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MeterDataUpdateUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MeterDataUpdateUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MeterDataUpdateUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MeterDataUpdateUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeterdata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.MeterData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.MeterDataUpdateUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MeterDataUpdateUserMessage} returns this
 */
proto.MeterDataUpdateUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MeterData meterData = 2;
 * @return {?proto.MeterData}
 */
proto.MeterDataUpdateUserMessage.prototype.getMeterdata = function() {
  return /** @type{?proto.MeterData} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.MeterData, 2));
};


/**
 * @param {?proto.MeterData|undefined} value
 * @return {!proto.MeterDataUpdateUserMessage} returns this
*/
proto.MeterDataUpdateUserMessage.prototype.setMeterdata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MeterDataUpdateUserMessage} returns this
 */
proto.MeterDataUpdateUserMessage.prototype.clearMeterdata = function() {
  return this.setMeterdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MeterDataUpdateUserMessage.prototype.hasMeterdata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDeviceConnectionState.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDeviceConnectionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDeviceConnectionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDeviceConnectionState.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
connectionstate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDeviceConnectionState}
 */
proto.UpdateDeviceConnectionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDeviceConnectionState;
  return proto.UpdateDeviceConnectionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDeviceConnectionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDeviceConnectionState}
 */
proto.UpdateDeviceConnectionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {!proto.ConnectionState} */ (reader.readEnum());
      msg.setConnectionstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDeviceConnectionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDeviceConnectionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDeviceConnectionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDeviceConnectionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateDeviceConnectionState.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateDeviceConnectionState} returns this
 */
proto.UpdateDeviceConnectionState.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConnectionState connectionState = 2;
 * @return {!proto.ConnectionState}
 */
proto.UpdateDeviceConnectionState.prototype.getConnectionstate = function() {
  return /** @type {!proto.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ConnectionState} value
 * @return {!proto.UpdateDeviceConnectionState} returns this
 */
proto.UpdateDeviceConnectionState.prototype.setConnectionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LogUpdateUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.LogUpdateUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LogUpdateUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LogUpdateUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LogUpdateUserMessage}
 */
proto.LogUpdateUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LogUpdateUserMessage;
  return proto.LogUpdateUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LogUpdateUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LogUpdateUserMessage}
 */
proto.LogUpdateUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LogUpdateUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LogUpdateUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LogUpdateUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LogUpdateUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.LogUpdateUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LogUpdateUserMessage} returns this
 */
proto.LogUpdateUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.LogUpdateUserMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.LogUpdateUserMessage} returns this
 */
proto.LogUpdateUserMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ErrorStatusUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ErrorStatusUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ErrorStatusUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ErrorStatusUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
errorstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
notelist: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ErrorStatusUserMessage}
 */
proto.ErrorStatusUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ErrorStatusUserMessage;
  return proto.ErrorStatusUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ErrorStatusUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ErrorStatusUserMessage}
 */
proto.ErrorStatusUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setErrorstatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ErrorStatusUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ErrorStatusUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ErrorStatusUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ErrorStatusUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorstatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNotelist();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ErrorStatusUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ErrorStatusUserMessage} returns this
 */
proto.ErrorStatusUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 errorStatus = 2;
 * @return {number}
 */
proto.ErrorStatusUserMessage.prototype.getErrorstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ErrorStatusUserMessage} returns this
 */
proto.ErrorStatusUserMessage.prototype.setErrorstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string noteList = 3;
 * @return {string}
 */
proto.ErrorStatusUserMessage.prototype.getNotelist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ErrorStatusUserMessage} returns this
 */
proto.ErrorStatusUserMessage.prototype.setNotelist = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateScheduleCompletedUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateScheduleCompletedUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateScheduleCompletedUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleCompletedUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
schedule: (f = msg.getSchedule()) && common_proto_pb.DeviceSchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateScheduleCompletedUserMessage}
 */
proto.UpdateScheduleCompletedUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateScheduleCompletedUserMessage;
  return proto.UpdateScheduleCompletedUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateScheduleCompletedUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateScheduleCompletedUserMessage}
 */
proto.UpdateScheduleCompletedUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.DeviceSchedule;
      reader.readMessage(value,common_proto_pb.DeviceSchedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateScheduleCompletedUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateScheduleCompletedUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateScheduleCompletedUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleCompletedUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.DeviceSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateScheduleCompletedUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleCompletedUserMessage} returns this
 */
proto.UpdateScheduleCompletedUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceSchedule schedule = 2;
 * @return {?proto.DeviceSchedule}
 */
proto.UpdateScheduleCompletedUserMessage.prototype.getSchedule = function() {
  return /** @type{?proto.DeviceSchedule} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DeviceSchedule, 2));
};


/**
 * @param {?proto.DeviceSchedule|undefined} value
 * @return {!proto.UpdateScheduleCompletedUserMessage} returns this
*/
proto.UpdateScheduleCompletedUserMessage.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleCompletedUserMessage} returns this
 */
proto.UpdateScheduleCompletedUserMessage.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleCompletedUserMessage.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateAllLightsStatusUserMessage.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateAllLightsStatusUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateAllLightsStatusUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateAllLightsStatusUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAllLightsStatusUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
lightsList: jspb.Message.toObjectList(msg.getLightsList(),
    common_proto_pb.LightStatusItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateAllLightsStatusUserMessage}
 */
proto.UpdateAllLightsStatusUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateAllLightsStatusUserMessage;
  return proto.UpdateAllLightsStatusUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateAllLightsStatusUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateAllLightsStatusUserMessage}
 */
proto.UpdateAllLightsStatusUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.LightStatusItem;
      reader.readMessage(value,common_proto_pb.LightStatusItem.deserializeBinaryFromReader);
      msg.addLights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateAllLightsStatusUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateAllLightsStatusUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateAllLightsStatusUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAllLightsStatusUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_proto_pb.LightStatusItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateAllLightsStatusUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAllLightsStatusUserMessage} returns this
 */
proto.UpdateAllLightsStatusUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated LightStatusItem lights = 2;
 * @return {!Array<!proto.LightStatusItem>}
 */
proto.UpdateAllLightsStatusUserMessage.prototype.getLightsList = function() {
  return /** @type{!Array<!proto.LightStatusItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.LightStatusItem, 2));
};


/**
 * @param {!Array<!proto.LightStatusItem>} value
 * @return {!proto.UpdateAllLightsStatusUserMessage} returns this
*/
proto.UpdateAllLightsStatusUserMessage.prototype.setLightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.LightStatusItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LightStatusItem}
 */
proto.UpdateAllLightsStatusUserMessage.prototype.addLights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.LightStatusItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateAllLightsStatusUserMessage} returns this
 */
proto.UpdateAllLightsStatusUserMessage.prototype.clearLightsList = function() {
  return this.setLightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateLightStatusUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateLightStatusUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateLightStatusUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLightStatusUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
sn: jspb.Message.getFieldWithDefault(msg, 2, ""),
lightid: jspb.Message.getFieldWithDefault(msg, 3, 0),
light: (f = msg.getLight()) && common_proto_pb.LightStatusItem.toObject(includeInstance, f),
stationid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateLightStatusUserMessage}
 */
proto.UpdateLightStatusUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateLightStatusUserMessage;
  return proto.UpdateLightStatusUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateLightStatusUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateLightStatusUserMessage}
 */
proto.UpdateLightStatusUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSn(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLightid(value);
      break;
    case 4:
      var value = new common_proto_pb.LightStatusItem;
      reader.readMessage(value,common_proto_pb.LightStatusItem.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateLightStatusUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateLightStatusUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateLightStatusUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateLightStatusUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLightid();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_proto_pb.LightStatusItem.serializeBinaryToWriter
    );
  }
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateLightStatusUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateLightStatusUserMessage} returns this
 */
proto.UpdateLightStatusUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sn = 2;
 * @return {string}
 */
proto.UpdateLightStatusUserMessage.prototype.getSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateLightStatusUserMessage} returns this
 */
proto.UpdateLightStatusUserMessage.prototype.setSn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 lightId = 3;
 * @return {number}
 */
proto.UpdateLightStatusUserMessage.prototype.getLightid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateLightStatusUserMessage} returns this
 */
proto.UpdateLightStatusUserMessage.prototype.setLightid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional LightStatusItem light = 4;
 * @return {?proto.LightStatusItem}
 */
proto.UpdateLightStatusUserMessage.prototype.getLight = function() {
  return /** @type{?proto.LightStatusItem} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.LightStatusItem, 4));
};


/**
 * @param {?proto.LightStatusItem|undefined} value
 * @return {!proto.UpdateLightStatusUserMessage} returns this
*/
proto.UpdateLightStatusUserMessage.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateLightStatusUserMessage} returns this
 */
proto.UpdateLightStatusUserMessage.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateLightStatusUserMessage.prototype.hasLight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string stationId = 5;
 * @return {string}
 */
proto.UpdateLightStatusUserMessage.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateLightStatusUserMessage} returns this
 */
proto.UpdateLightStatusUserMessage.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceOnchangeUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceOnchangeUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceOnchangeUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceOnchangeUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
updatedeviceconnectionstate: (f = msg.getUpdatedeviceconnectionstate()) && proto.UpdateDeviceConnectionState.toObject(includeInstance, f),
devicestateonchange: (f = msg.getDevicestateonchange()) && proto.StateOnChangeUserMessage.toObject(includeInstance, f),
meterdataupdateusermessage: (f = msg.getMeterdataupdateusermessage()) && proto.MeterDataUpdateUserMessage.toObject(includeInstance, f),
logupdate: (f = msg.getLogupdate()) && proto.LogUpdateUserMessage.toObject(includeInstance, f),
errorstatususermessage: (f = msg.getErrorstatususermessage()) && proto.ErrorStatusUserMessage.toObject(includeInstance, f),
updateschedulecompletedusermessage: (f = msg.getUpdateschedulecompletedusermessage()) && proto.UpdateScheduleCompletedUserMessage.toObject(includeInstance, f),
updatelightstatususermessage: (f = msg.getUpdatelightstatususermessage()) && proto.UpdateLightStatusUserMessage.toObject(includeInstance, f),
updatealllightsstatususermessage: (f = msg.getUpdatealllightsstatususermessage()) && proto.UpdateAllLightsStatusUserMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceOnchangeUserMessage}
 */
proto.DeviceOnchangeUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceOnchangeUserMessage;
  return proto.DeviceOnchangeUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceOnchangeUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceOnchangeUserMessage}
 */
proto.DeviceOnchangeUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UpdateDeviceConnectionState;
      reader.readMessage(value,proto.UpdateDeviceConnectionState.deserializeBinaryFromReader);
      msg.setUpdatedeviceconnectionstate(value);
      break;
    case 2:
      var value = new proto.StateOnChangeUserMessage;
      reader.readMessage(value,proto.StateOnChangeUserMessage.deserializeBinaryFromReader);
      msg.setDevicestateonchange(value);
      break;
    case 3:
      var value = new proto.MeterDataUpdateUserMessage;
      reader.readMessage(value,proto.MeterDataUpdateUserMessage.deserializeBinaryFromReader);
      msg.setMeterdataupdateusermessage(value);
      break;
    case 4:
      var value = new proto.LogUpdateUserMessage;
      reader.readMessage(value,proto.LogUpdateUserMessage.deserializeBinaryFromReader);
      msg.setLogupdate(value);
      break;
    case 5:
      var value = new proto.ErrorStatusUserMessage;
      reader.readMessage(value,proto.ErrorStatusUserMessage.deserializeBinaryFromReader);
      msg.setErrorstatususermessage(value);
      break;
    case 6:
      var value = new proto.UpdateScheduleCompletedUserMessage;
      reader.readMessage(value,proto.UpdateScheduleCompletedUserMessage.deserializeBinaryFromReader);
      msg.setUpdateschedulecompletedusermessage(value);
      break;
    case 7:
      var value = new proto.UpdateLightStatusUserMessage;
      reader.readMessage(value,proto.UpdateLightStatusUserMessage.deserializeBinaryFromReader);
      msg.setUpdatelightstatususermessage(value);
      break;
    case 8:
      var value = new proto.UpdateAllLightsStatusUserMessage;
      reader.readMessage(value,proto.UpdateAllLightsStatusUserMessage.deserializeBinaryFromReader);
      msg.setUpdatealllightsstatususermessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceOnchangeUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceOnchangeUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceOnchangeUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceOnchangeUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedeviceconnectionstate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UpdateDeviceConnectionState.serializeBinaryToWriter
    );
  }
  f = message.getDevicestateonchange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.StateOnChangeUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getMeterdataupdateusermessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.MeterDataUpdateUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getLogupdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.LogUpdateUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getErrorstatususermessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ErrorStatusUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getUpdateschedulecompletedusermessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.UpdateScheduleCompletedUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getUpdatelightstatususermessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.UpdateLightStatusUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getUpdatealllightsstatususermessage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.UpdateAllLightsStatusUserMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateDeviceConnectionState updateDeviceConnectionState = 1;
 * @return {?proto.UpdateDeviceConnectionState}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatedeviceconnectionstate = function() {
  return /** @type{?proto.UpdateDeviceConnectionState} */ (
    jspb.Message.getWrapperField(this, proto.UpdateDeviceConnectionState, 1));
};


/**
 * @param {?proto.UpdateDeviceConnectionState|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatedeviceconnectionstate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatedeviceconnectionstate = function() {
  return this.setUpdatedeviceconnectionstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatedeviceconnectionstate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StateOnChangeUserMessage deviceStateOnChange = 2;
 * @return {?proto.StateOnChangeUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getDevicestateonchange = function() {
  return /** @type{?proto.StateOnChangeUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.StateOnChangeUserMessage, 2));
};


/**
 * @param {?proto.StateOnChangeUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setDevicestateonchange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearDevicestateonchange = function() {
  return this.setDevicestateonchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasDevicestateonchange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MeterDataUpdateUserMessage meterDataUpdateUserMessage = 3;
 * @return {?proto.MeterDataUpdateUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getMeterdataupdateusermessage = function() {
  return /** @type{?proto.MeterDataUpdateUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.MeterDataUpdateUserMessage, 3));
};


/**
 * @param {?proto.MeterDataUpdateUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setMeterdataupdateusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearMeterdataupdateusermessage = function() {
  return this.setMeterdataupdateusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasMeterdataupdateusermessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LogUpdateUserMessage logUpdate = 4;
 * @return {?proto.LogUpdateUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getLogupdate = function() {
  return /** @type{?proto.LogUpdateUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.LogUpdateUserMessage, 4));
};


/**
 * @param {?proto.LogUpdateUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setLogupdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearLogupdate = function() {
  return this.setLogupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasLogupdate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorStatusUserMessage errorStatusUserMessage = 5;
 * @return {?proto.ErrorStatusUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getErrorstatususermessage = function() {
  return /** @type{?proto.ErrorStatusUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.ErrorStatusUserMessage, 5));
};


/**
 * @param {?proto.ErrorStatusUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setErrorstatususermessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearErrorstatususermessage = function() {
  return this.setErrorstatususermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasErrorstatususermessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UpdateScheduleCompletedUserMessage updateScheduleCompletedUserMessage = 6;
 * @return {?proto.UpdateScheduleCompletedUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdateschedulecompletedusermessage = function() {
  return /** @type{?proto.UpdateScheduleCompletedUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.UpdateScheduleCompletedUserMessage, 6));
};


/**
 * @param {?proto.UpdateScheduleCompletedUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdateschedulecompletedusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdateschedulecompletedusermessage = function() {
  return this.setUpdateschedulecompletedusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdateschedulecompletedusermessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UpdateLightStatusUserMessage updateLightStatusUserMessage = 7;
 * @return {?proto.UpdateLightStatusUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatelightstatususermessage = function() {
  return /** @type{?proto.UpdateLightStatusUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.UpdateLightStatusUserMessage, 7));
};


/**
 * @param {?proto.UpdateLightStatusUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatelightstatususermessage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatelightstatususermessage = function() {
  return this.setUpdatelightstatususermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatelightstatususermessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional UpdateAllLightsStatusUserMessage updateAllLightsStatusUserMessage = 8;
 * @return {?proto.UpdateAllLightsStatusUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatealllightsstatususermessage = function() {
  return /** @type{?proto.UpdateAllLightsStatusUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.UpdateAllLightsStatusUserMessage, 8));
};


/**
 * @param {?proto.UpdateAllLightsStatusUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatealllightsstatususermessage = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatealllightsstatususermessage = function() {
  return this.setUpdatealllightsstatususermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatealllightsstatususermessage = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
authusermessage: (f = msg.getAuthusermessage()) && proto.AuthUserMessage.toObject(includeInstance, f),
deviceonchangeusermessage: (f = msg.getDeviceonchangeusermessage()) && proto.DeviceOnchangeUserMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserMessage}
 */
proto.UserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserMessage;
  return proto.UserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserMessage}
 */
proto.UserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AuthUserMessage;
      reader.readMessage(value,proto.AuthUserMessage.deserializeBinaryFromReader);
      msg.setAuthusermessage(value);
      break;
    case 2:
      var value = new proto.DeviceOnchangeUserMessage;
      reader.readMessage(value,proto.DeviceOnchangeUserMessage.deserializeBinaryFromReader);
      msg.setDeviceonchangeusermessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthusermessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.AuthUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getDeviceonchangeusermessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceOnchangeUserMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthUserMessage authUserMessage = 1;
 * @return {?proto.AuthUserMessage}
 */
proto.UserMessage.prototype.getAuthusermessage = function() {
  return /** @type{?proto.AuthUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.AuthUserMessage, 1));
};


/**
 * @param {?proto.AuthUserMessage|undefined} value
 * @return {!proto.UserMessage} returns this
*/
proto.UserMessage.prototype.setAuthusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserMessage} returns this
 */
proto.UserMessage.prototype.clearAuthusermessage = function() {
  return this.setAuthusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserMessage.prototype.hasAuthusermessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceOnchangeUserMessage deviceOnchangeUserMessage = 2;
 * @return {?proto.DeviceOnchangeUserMessage}
 */
proto.UserMessage.prototype.getDeviceonchangeusermessage = function() {
  return /** @type{?proto.DeviceOnchangeUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.DeviceOnchangeUserMessage, 2));
};


/**
 * @param {?proto.DeviceOnchangeUserMessage|undefined} value
 * @return {!proto.UserMessage} returns this
*/
proto.UserMessage.prototype.setDeviceonchangeusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserMessage} returns this
 */
proto.UserMessage.prototype.clearDeviceonchangeusermessage = function() {
  return this.setDeviceonchangeusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserMessage.prototype.hasDeviceonchangeusermessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto);
