import { useCallback, useEffect, useRef, useState } from "react"
import { Loader } from "@googlemaps/js-api-loader";
import { Button } from "react-bootstrap";
import { BottomSheet } from "./components/BottomSheet";
import { LightService } from "../../../services/LightService";
import { AppSession } from "../../../services/session/AppSession";

const loader = new Loader({
    apiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    version: "weekly",
    libraries: ['places'],
});

function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

export function GooggleMapMobile(props) {
    const refMarkers = useRef([]);
    const refMap = useRef(null);
    const refWindowInfoElementTitle = useRef(null);
    const markerLibraryRef = useRef(null);
    const mapLibraryRef = useRef(null);
    const [currentPanel, setCurrentPanel] = useState(null);

    const loadDataMarker = useCallback(() => {
        const _serv = new LightService();
        return _serv.getListLights(props.stationId);
    }, []);


    const saveAllMarker = useCallback(() => {
        const _serv = new LightService();
        if (refMarkers.current.length) {
            refMarkers.current.forEach((v) => {
                _serv.updateLightInfo(props.stationId, v.id, { lat: v.latitude, lng: v.longitude, name: v.name, });
            });
            window.alert("Lưu toạ độ thành công");
        }
    }, []);

    const loadMarker = useCallback(async () => {
        const { AdvancedMarkerElement } = markerLibraryRef.current;
        const { InfoWindow } = mapLibraryRef.current;
        const infoWindow = new InfoWindow();

        for (let i = 0; i < refMarkers.current.length; i++) {
            if (refMarkers.current[i]._marker) {
                refMarkers.current[i]._marker.map = null;
            }
        }

        refMarkers.current = await loadDataMarker();

        for (let i = 0; i < refMarkers.current.length; i++) {

            const iconMarker = document.createElement("div");
            iconMarker.style.display = "flex";
            iconMarker.style.flexDirection = "column";
            iconMarker.style.alignItems = "center";
            iconMarker.style.gap = "4px";

            const icMarkerTitle = document.createElement("span");
            icMarkerTitle.id = `${refMarkers.current[i].id}-title`;
            icMarkerTitle.textContent = refMarkers.current[i].name;
            icMarkerTitle.style.fontWeight = "bold";
            icMarkerTitle.style.fontSize = "16px";

            const icMarkerLabel = document.createElement("div");
            icMarkerLabel.id = `${refMarkers.current[i].id}-label`;
            icMarkerLabel.style.backgroundColor = refMarkers.current[i].state ? 'yellow' : 'gray';
            icMarkerLabel.style.width = "36px";
            icMarkerLabel.style.height = "36px";
            icMarkerLabel.style.borderRadius = "100%";

            iconMarker.appendChild(icMarkerTitle);
            iconMarker.appendChild(icMarkerLabel);

            const windowInfoElement = document.createElement("div");
            windowInfoElement.style.display = "flex";
            windowInfoElement.style.flexDirection = "column";
            windowInfoElement.style.alignItems = "center";
            windowInfoElement.style.gap = "4px";
            windowInfoElement.style.backgroundColor = "#FFFFFF";
            const windowInfoElementTitle = document.createElement("span");
            windowInfoElementTitle.id = `window-info-title`;
            windowInfoElementTitle.textContent = refMarkers.current[i].name;
            windowInfoElementTitle.style.fontWeight = "bold";
            windowInfoElementTitle.style.fontSize = "16px";
            const windowInfoElementButton = document.createElement("button");
            windowInfoElementButton.appendChild(document.createTextNode("Chi tiết"));
            windowInfoElementButton.style.fontWeight = "bold";
            windowInfoElementButton.style.fontSize = "16px";
            windowInfoElementButton.style.color = "#FFFFFF";
            windowInfoElementButton.style.backgroundColor = "#2196F3";
            windowInfoElementButton.style.border = "none";
            windowInfoElementButton.style.outline = "none";
            windowInfoElementButton.style.width = "100%";
            windowInfoElementButton.style.padding = "4px";

            windowInfoElementButton.addEventListener("click", () => {
                onOpenPanel(i);
                infoWindow.close();
            });

            windowInfoElement.appendChild(windowInfoElementTitle);
            windowInfoElement.appendChild(windowInfoElementButton);

            refWindowInfoElementTitle.current = windowInfoElementTitle;

            const marker = new AdvancedMarkerElement({
                map: refMap.current,
                position: { lat: refMarkers.current[i].latitude, lng: refMarkers.current[i].longitude },
                title: refMarkers.current[i].name,
                gmpDraggable: true,
                gmpClickable: true,
                content: iconMarker,
            });

            refMarkers.current[i]._marker = marker;

            if (!isTouchDevice) {
                setTimeout(() => {
                    if (refMarkers.current[i].__isDragging) {
                        return;
                    }
                    infoWindow.close();
                    infoWindow.setContent(windowInfoElement);
                    infoWindow.open(marker.map, marker);
                    refMap.current.panTo(marker.position);
                }, 300);
            }
            else {
                marker.content.addEventListener("touchstart", () => {
                    setTimeout(() => {
                        if (refMarkers.current[i].__isDragging) {
                            return;
                        }
                        infoWindow.close();
                        infoWindow.setContent(windowInfoElement);
                        infoWindow.open(marker.map, marker);
                        refMap.current.panTo(marker.position);
                    }, 300);

                });
            }

            marker.addListener("dragend", () => {
                refMarkers.current[i].latitude = marker.position.lat;
                refMarkers.current[i].longitude = marker.position.lng;
                infoWindow.close();
                refMarkers.current[i].__isDragging = false;
            });

            marker.addListener("dragstart", () => {
                refMarkers.current[i].__isDragging = true;
            });
        }

        if (refMarkers.current.length) {
            refMap.current.setCenter({ lat: refMarkers.current[0].latitude, lng: refMarkers.current[0].longitude });
            refMap.current.panTo({ lat: refMarkers.current[0].latitude, lng: refMarkers.current[0].longitude });
        }

    }, [loadDataMarker]);

    const initMap = useCallback(async () => {
        if (!mapLibraryRef.current) {
            mapLibraryRef.current = await loader.importLibrary('maps');
        }
        const { Map } = mapLibraryRef.current;
        if (!markerLibraryRef.current) {
            markerLibraryRef.current = await loader.importLibrary('marker');
        }
        refMap.current = new Map(document.getElementById("maps-mobile-traffic-light"), {
            center: { lat: refMarkers.current.length ? refMarkers.current[0].latitude : 0, lng: refMarkers.current.length ? refMarkers.current[0].longitude : 0 },
            zoom: 20,
            mapId: "maps-mobile-traffic-light",
        });
        await loadMarker();
    }, [loadMarker]);

    function onClosePanel() {
        setCurrentPanel(null);
    }

    function onOpenPanel(index) {
        setCurrentPanel(refMarkers.current[index]);
    }

    useEffect(() => {
        if (!refMap.current) {
            initMap().finally(() => {
                AppSession.streamLightsData.subscribe((v) => {
                    if (v?.stationId === props.stationId) {
                        const idx = refMarkers.current.findIndex(e => e.id === v?.light?.id);
                        if (idx > -1) {
                            refMarkers.current[idx].name = v?.light?.sn ?? refMarkers.current[idx].name;
                            refMarkers.current[idx].state = v?.light?.state ?? refMarkers.current[idx].state;
                            const _title = document.getElementById(`${refMarkers.current[idx].id}-title`);
                            const _label = document.getElementById(`${refMarkers.current[idx].id}-label`);
                            if (_title) {
                                _title.textContent = refMarkers.current[idx].name;
                            }
                            if (_label) {
                                _label.style.backgroundColor = refMarkers.current[idx].state ? 'yellow' : 'gray';
                            }
                            if (refWindowInfoElementTitle?.current) {
                                refWindowInfoElementTitle.current.textContent = refMarkers.current[idx].name;
                            }
                        }
                    }
                });
            });
        }
    }, [initMap]);

    return (
        <div style={{ width: "100%", height: "fit-content", display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
            <div style={{ width: "100%", height: "fit-content", display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                <Button variant='secondary' style={{ width: '40%' }} onClick={() => { loadMarker(props.stationId); }}>
                    <span>Tải lại tọa độ</span>
                </Button>
                <Button variant='primary' style={{ width: '40%' }} onClick={() => { saveAllMarker() }}>
                    <span>Lưu toạ độ</span>
                </Button>
            </div>
            <div id="maps-mobile-traffic-light" style={{ width: "100%", height: "300px" }} />
            {currentPanel !== null && <BottomSheet data={currentPanel} closePanel={() => { onClosePanel(); loadMarker(props.stationId); }} stationId={props.stationId} />}
        </div>
    )
}