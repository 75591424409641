import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { LightService } from '../../../../services/LightService';
import { AppSession } from '../../../../services/session/AppSession';
const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100%",
    background: "rgba(31, 11, 11, 0.5)",
    backdropFilter: "blur(10px)",
    alignItems: "center",
    justifyContent: "flex-end",
    pointerEvents: "none",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    minHeight: 'calc(100vh - 6rem)'
};

const bottomSheetStyle = {
    width: "100%",
    height: "60vh",
    background: "white",
    borderRadius: "16px 16px 0 0",
    boxShadow: "0 -4px 8px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    alignItems: "center",
    overflow: "hidden",
    pointerEvents: "auto",
    gap: "12px",
};

export function BottomSheet(props) {
    const { data, closePanel, stationId } = props;
    const [dim, setDim] = useState(0);
    const [name, setName] = useState('');
    const [paramMonitor, setParamMonitor] = useState({
        power: 0,
        voltage: 0,
        current: 0,
        cosphi: 0,
        brightness: 0,
    });

    const updateLightState = useCallback(async () => {
        const _serv = new LightService();
        await _serv.updateLightState(stationId, data?.id, { dim: dim, state: dim > 0 });
        await _serv.updateLightInfo(stationId, data?.id, { lat: data?.latitude, lng: data?.longitude, name: name });
    }, [dim, name]);

    useEffect(() => {
        const _serv = new LightService();
        _serv.getLightDetail(stationId, data.id).then((v) => {
            setParamMonitor({
                power: v?.power,
                voltage: v?.voltage,
                current: v?.current,
                cosphi: v?.cosphi,
                brightness: v?.brightness,
            });
            setDim(v?.dim ?? dim);
            setName(v?.name ?? name);

            AppSession.streamLightsData.subscribe((v) => {
                if (v && v?.stationId === props.stationId && data.id === v?.light?.id) {
                    setParamMonitor({
                        power: v?.light?.power,
                        voltage: v?.light?.voltage,
                        current: v?.light?.current,
                        cosphi: v?.light?.cosphi,
                        brightness: v?.light?.brightness,
                    });
                    setDim(v?.light?.dim ?? dim);
                }
            });
        });
    }, []);

    return (
        <div style={{ ...overlayStyle, display: data !== null ? 'flex' : 'none', }}>
            <div style={{ ...bottomSheetStyle }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",

                }}>
                    <span style={{ fontSize: "24px", fontWeight: "bold" }} onClick={() => {
                        closePanel();
                    }}>Đóng</span>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    overflowY: "auto",
                    width: "100%",
                    flex: "1",
                    maxHeight: "100%",
                    gap: "12px",
                }}>
                    <div style={{
                        width: "100%",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <Form.Label style={{ width: "100%", fontWeight: "bold", textAlign: "start", color: "#333333" }}>Điều khiển</Form.Label>
                        <div style={{
                            width: "100%",
                            gap: "16px",
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "12px",
                        }}>
                            <Button style={{
                                padding: '12px 12px',
                                width: '100%'
                            }}
                                variant={'info'}
                                onClick={() => { setDim(0) }}
                            >
                                <p style={{
                                    margin: 'auto',
                                    fontSize: '18px',
                                    textAlign: 'center'
                                }}>
                                    {'Dim(0%)'}
                                </p>
                            </Button>
                            <Button style={{
                                padding: '12px 12px',
                                width: '100%'
                            }}
                                variant={'info'}
                                onClick={() => { setDim(100) }}
                            >
                                <p style={{
                                    margin: 'auto',
                                    fontSize: '18px',
                                    textAlign: 'center'
                                }}>
                                    {'Dim(100%)'}
                                </p>
                            </Button>
                        </div>
                        <div style={{
                            width: "100%",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <RangeSlider
                                style={{
                                    width: '100%'
                                }}
                                size="lg"
                                defaultValue={dim}
                                value={dim}
                                onChange={e => { setDim(Number(e.target.value)) }}
                                max={100}
                                min={0}
                                tooltipLabel={(v) => {
                                    return `Dim: ${v}%`
                                }}
                            />
                        </div>
                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                    }}>
                        <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333" }} htmlFor="light-name">Tên thiết bị</Form.Label>
                        <Form.Control
                            style={{ width: "100%" }}
                            type="text"
                            id="light-name"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            defaultValue={data?.name}
                            placeholder={data?.name}
                        />
                    </div>

                    <div style={{
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}>
                        <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333", fontWeight: "bold" }}>Giám sát thông số</Form.Label>
                        <div style={{
                            width: "100%",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333" }}>{'Điện áp (V)'}</Form.Label>
                            <Form.Control
                                style={{ width: "100%", textAlign: 'end' }}
                                type="text"
                                value={paramMonitor.voltage}
                                disabled
                            />
                        </div>
                        <div style={{
                            width: "100%",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333" }}>{'Dòng điện (A)'}</Form.Label>
                            <Form.Control
                                style={{ width: "100%", textAlign: 'end' }}
                                type="text"
                                value={(Number(paramMonitor.current) / 1000).toFixed(3)}
                                disabled
                            />
                        </div>
                        <div style={{
                            width: "100%",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333" }}>{'Công suất (W)'}</Form.Label>
                            <Form.Control
                                style={{ width: "100%", textAlign: 'end' }}
                                type="text"
                                value={paramMonitor.power}
                                disabled
                            />
                        </div>
                        <div style={{
                            width: "100%",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333" }}>{'CosPhi'}</Form.Label>
                            <Form.Control
                                style={{ width: "100%", textAlign: 'end' }}
                                type="text"
                                value={Number(paramMonitor.cosphi).toFixed(3)}
                                disabled
                            />
                        </div>
                        <div style={{
                            width: "100%",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333" }}>{'Độ sáng (%)'}</Form.Label>
                            <Form.Control
                                style={{ width: "100%", textAlign: 'end' }}
                                type="text"
                                value={paramMonitor.brightness}
                                disabled
                            />
                        </div>
                        <div style={{
                            width: "100%",
                            gap: "8px",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Form.Label style={{ width: "100%", textAlign: "start", color: "#333333" }}>{'Trạng thái hoạt động'}</Form.Label>
                            <Form.Control
                                style={{ width: "100%", textAlign: 'end' }}
                                type="text"
                                value={dim ? 'ON' : "OFF"}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <Button variant='primary' style={{ width: '100%' }} onClick={async () => {
                    try {
                        await updateLightState();
                        window.alert("Thiết lập thành công");
                    } catch (error) {
                        console.error(error);
                        window.alert("Thiết lập thất bại");
                    }
                }}>
                    <span>Thiết lập</span>
                </Button>
            </div>
        </div >
    );
}