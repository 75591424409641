// source: common_proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.Account', null, global);
goog.exportSymbol('proto.AccountRole', null, global);
goog.exportSymbol('proto.ActiveLamp', null, global);
goog.exportSymbol('proto.ConnectionState', null, global);
goog.exportSymbol('proto.Device', null, global);
goog.exportSymbol('proto.DeviceSchedule', null, global);
goog.exportSymbol('proto.DeviceState', null, global);
goog.exportSymbol('proto.DeviceType', null, global);
goog.exportSymbol('proto.HMTime', null, global);
goog.exportSymbol('proto.LightItem', null, global);
goog.exportSymbol('proto.LightStatusItem', null, global);
goog.exportSymbol('proto.MeterData', null, global);
goog.exportSymbol('proto.PowerMode', null, global);
goog.exportSymbol('proto.PowerState', null, global);
goog.exportSymbol('proto.StatusCode', null, global);
goog.exportSymbol('proto.SunshineActive', null, global);
goog.exportSymbol('proto.TimerActive', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Account.displayName = 'proto.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Device.displayName = 'proto.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HMTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.HMTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.HMTime.displayName = 'proto.HMTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActiveLamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ActiveLamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActiveLamp.displayName = 'proto.ActiveLamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SunshineActive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SunshineActive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SunshineActive.displayName = 'proto.SunshineActive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimerActive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TimerActive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimerActive.displayName = 'proto.TimerActive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DeviceSchedule.repeatedFields_, null);
};
goog.inherits(proto.DeviceSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceSchedule.displayName = 'proto.DeviceSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LightStatusItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LightStatusItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LightStatusItem.displayName = 'proto.LightStatusItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LightItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LightItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LightItem.displayName = 'proto.LightItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PowerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PowerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PowerState.displayName = 'proto.PowerState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceState.displayName = 'proto.DeviceState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MeterData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MeterData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MeterData.displayName = 'proto.MeterData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatusCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StatusCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatusCode.displayName = 'proto.StatusCode';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
username: jspb.Message.getFieldWithDefault(msg, 2, ""),
role: jspb.Message.getFieldWithDefault(msg, 3, 0),
createdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
iscurrentaccount: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Account}
 */
proto.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Account;
  return proto.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Account}
 */
proto.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {!proto.AccountRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscurrentaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIscurrentaccount();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.Account.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userName = 2;
 * @return {string}
 */
proto.Account.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountRole role = 3;
 * @return {!proto.AccountRole}
 */
proto.Account.prototype.getRole = function() {
  return /** @type {!proto.AccountRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.AccountRole} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string createDate = 4;
 * @return {string}
 */
proto.Account.prototype.getCreatedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setCreatedate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isCurrentAccount = 5;
 * @return {boolean}
 */
proto.Account.prototype.getIscurrentaccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setIscurrentaccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
devicetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
devicestate: (f = msg.getDevicestate()) && proto.DeviceState.toObject(includeInstance, f),
stationname: jspb.Message.getFieldWithDefault(msg, 4, ""),
hardwareid: jspb.Message.getFieldWithDefault(msg, 5, ""),
numphase: jspb.Message.getFieldWithDefault(msg, 6, 0),
lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
log: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Device}
 */
proto.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Device;
  return proto.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Device}
 */
proto.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicetype(value);
      break;
    case 3:
      var value = new proto.DeviceState;
      reader.readMessage(value,proto.DeviceState.deserializeBinaryFromReader);
      msg.setDevicestate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumphase(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicetype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicestate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DeviceState.serializeBinaryToWriter
    );
  }
  f = message.getStationname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumphase();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.Device.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deviceType = 2;
 * @return {string}
 */
proto.Device.prototype.getDevicetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setDevicetype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviceState deviceState = 3;
 * @return {?proto.DeviceState}
 */
proto.Device.prototype.getDevicestate = function() {
  return /** @type{?proto.DeviceState} */ (
    jspb.Message.getWrapperField(this, proto.DeviceState, 3));
};


/**
 * @param {?proto.DeviceState|undefined} value
 * @return {!proto.Device} returns this
*/
proto.Device.prototype.setDevicestate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.clearDevicestate = function() {
  return this.setDevicestate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Device.prototype.hasDevicestate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string stationName = 4;
 * @return {string}
 */
proto.Device.prototype.getStationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setStationname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string hardwareId = 5;
 * @return {string}
 */
proto.Device.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 numPhase = 6;
 * @return {number}
 */
proto.Device.prototype.getNumphase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setNumphase = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float lat = 7;
 * @return {number}
 */
proto.Device.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float log = 8;
 * @return {number}
 */
proto.Device.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.HMTime.prototype.toObject = function(opt_includeInstance) {
  return proto.HMTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.HMTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HMTime.toObject = function(includeInstance, msg) {
  var f, obj = {
hour: jspb.Message.getFieldWithDefault(msg, 1, 0),
minute: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HMTime}
 */
proto.HMTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.HMTime;
  return proto.HMTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HMTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HMTime}
 */
proto.HMTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHour(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HMTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.HMTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HMTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HMTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHour();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMinute();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 hour = 1;
 * @return {number}
 */
proto.HMTime.prototype.getHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.HMTime} returns this
 */
proto.HMTime.prototype.setHour = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 minute = 2;
 * @return {number}
 */
proto.HMTime.prototype.getMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.HMTime} returns this
 */
proto.HMTime.prototype.setMinute = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActiveLamp.prototype.toObject = function(opt_includeInstance) {
  return proto.ActiveLamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActiveLamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActiveLamp.toObject = function(includeInstance, msg) {
  var f, obj = {
begin: (f = msg.getBegin()) && proto.HMTime.toObject(includeInstance, f),
end: (f = msg.getEnd()) && proto.HMTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActiveLamp}
 */
proto.ActiveLamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActiveLamp;
  return proto.ActiveLamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActiveLamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActiveLamp}
 */
proto.ActiveLamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.HMTime;
      reader.readMessage(value,proto.HMTime.deserializeBinaryFromReader);
      msg.setBegin(value);
      break;
    case 2:
      var value = new proto.HMTime;
      reader.readMessage(value,proto.HMTime.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActiveLamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActiveLamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActiveLamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActiveLamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBegin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.HMTime.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.HMTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional HMTime begin = 1;
 * @return {?proto.HMTime}
 */
proto.ActiveLamp.prototype.getBegin = function() {
  return /** @type{?proto.HMTime} */ (
    jspb.Message.getWrapperField(this, proto.HMTime, 1));
};


/**
 * @param {?proto.HMTime|undefined} value
 * @return {!proto.ActiveLamp} returns this
*/
proto.ActiveLamp.prototype.setBegin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActiveLamp} returns this
 */
proto.ActiveLamp.prototype.clearBegin = function() {
  return this.setBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActiveLamp.prototype.hasBegin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HMTime end = 2;
 * @return {?proto.HMTime}
 */
proto.ActiveLamp.prototype.getEnd = function() {
  return /** @type{?proto.HMTime} */ (
    jspb.Message.getWrapperField(this, proto.HMTime, 2));
};


/**
 * @param {?proto.HMTime|undefined} value
 * @return {!proto.ActiveLamp} returns this
*/
proto.ActiveLamp.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActiveLamp} returns this
 */
proto.ActiveLamp.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActiveLamp.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SunshineActive.prototype.toObject = function(opt_includeInstance) {
  return proto.SunshineActive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SunshineActive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SunshineActive.toObject = function(includeInstance, msg) {
  var f, obj = {
activelamp: (f = msg.getActivelamp()) && proto.ActiveLamp.toObject(includeInstance, f),
useoffset: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SunshineActive}
 */
proto.SunshineActive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SunshineActive;
  return proto.SunshineActive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SunshineActive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SunshineActive}
 */
proto.SunshineActive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ActiveLamp;
      reader.readMessage(value,proto.ActiveLamp.deserializeBinaryFromReader);
      msg.setActivelamp(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseoffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SunshineActive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SunshineActive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SunshineActive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SunshineActive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivelamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ActiveLamp.serializeBinaryToWriter
    );
  }
  f = message.getUseoffset();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional ActiveLamp activeLamp = 1;
 * @return {?proto.ActiveLamp}
 */
proto.SunshineActive.prototype.getActivelamp = function() {
  return /** @type{?proto.ActiveLamp} */ (
    jspb.Message.getWrapperField(this, proto.ActiveLamp, 1));
};


/**
 * @param {?proto.ActiveLamp|undefined} value
 * @return {!proto.SunshineActive} returns this
*/
proto.SunshineActive.prototype.setActivelamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SunshineActive} returns this
 */
proto.SunshineActive.prototype.clearActivelamp = function() {
  return this.setActivelamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SunshineActive.prototype.hasActivelamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool useOffset = 2;
 * @return {boolean}
 */
proto.SunshineActive.prototype.getUseoffset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SunshineActive} returns this
 */
proto.SunshineActive.prototype.setUseoffset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimerActive.prototype.toObject = function(opt_includeInstance) {
  return proto.TimerActive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimerActive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimerActive.toObject = function(includeInstance, msg) {
  var f, obj = {
activelamp: (f = msg.getActivelamp()) && proto.ActiveLamp.toObject(includeInstance, f),
channel1: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
channel2: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
channel3: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimerActive}
 */
proto.TimerActive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimerActive;
  return proto.TimerActive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimerActive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimerActive}
 */
proto.TimerActive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ActiveLamp;
      reader.readMessage(value,proto.ActiveLamp.deserializeBinaryFromReader);
      msg.setActivelamp(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannel1(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannel2(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannel3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimerActive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimerActive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimerActive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimerActive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivelamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ActiveLamp.serializeBinaryToWriter
    );
  }
  f = message.getChannel1();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getChannel2();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getChannel3();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional ActiveLamp activeLamp = 1;
 * @return {?proto.ActiveLamp}
 */
proto.TimerActive.prototype.getActivelamp = function() {
  return /** @type{?proto.ActiveLamp} */ (
    jspb.Message.getWrapperField(this, proto.ActiveLamp, 1));
};


/**
 * @param {?proto.ActiveLamp|undefined} value
 * @return {!proto.TimerActive} returns this
*/
proto.TimerActive.prototype.setActivelamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TimerActive} returns this
 */
proto.TimerActive.prototype.clearActivelamp = function() {
  return this.setActivelamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TimerActive.prototype.hasActivelamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool channel1 = 2;
 * @return {boolean}
 */
proto.TimerActive.prototype.getChannel1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TimerActive} returns this
 */
proto.TimerActive.prototype.setChannel1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool channel2 = 3;
 * @return {boolean}
 */
proto.TimerActive.prototype.getChannel2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TimerActive} returns this
 */
proto.TimerActive.prototype.setChannel2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool channel3 = 4;
 * @return {boolean}
 */
proto.TimerActive.prototype.getChannel3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TimerActive} returns this
 */
proto.TimerActive.prototype.setChannel3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DeviceSchedule.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
usesunshine: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
sunshineactive: (f = msg.getSunshineactive()) && proto.SunshineActive.toObject(includeInstance, f),
timeractiveList: jspb.Message.toObjectList(msg.getTimeractiveList(),
    proto.TimerActive.toObject, includeInstance),
channel1: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
channel2: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
channel3: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceSchedule}
 */
proto.DeviceSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceSchedule;
  return proto.DeviceSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceSchedule}
 */
proto.DeviceSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsesunshine(value);
      break;
    case 2:
      var value = new proto.SunshineActive;
      reader.readMessage(value,proto.SunshineActive.deserializeBinaryFromReader);
      msg.setSunshineactive(value);
      break;
    case 3:
      var value = new proto.TimerActive;
      reader.readMessage(value,proto.TimerActive.deserializeBinaryFromReader);
      msg.addTimeractive(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannel1(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannel2(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannel3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsesunshine();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSunshineactive();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.SunshineActive.serializeBinaryToWriter
    );
  }
  f = message.getTimeractiveList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.TimerActive.serializeBinaryToWriter
    );
  }
  f = message.getChannel1();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getChannel2();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getChannel3();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool useSunshine = 1;
 * @return {boolean}
 */
proto.DeviceSchedule.prototype.getUsesunshine = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceSchedule} returns this
 */
proto.DeviceSchedule.prototype.setUsesunshine = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SunshineActive sunshineActive = 2;
 * @return {?proto.SunshineActive}
 */
proto.DeviceSchedule.prototype.getSunshineactive = function() {
  return /** @type{?proto.SunshineActive} */ (
    jspb.Message.getWrapperField(this, proto.SunshineActive, 2));
};


/**
 * @param {?proto.SunshineActive|undefined} value
 * @return {!proto.DeviceSchedule} returns this
*/
proto.DeviceSchedule.prototype.setSunshineactive = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceSchedule} returns this
 */
proto.DeviceSchedule.prototype.clearSunshineactive = function() {
  return this.setSunshineactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceSchedule.prototype.hasSunshineactive = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TimerActive timerActive = 3;
 * @return {!Array<!proto.TimerActive>}
 */
proto.DeviceSchedule.prototype.getTimeractiveList = function() {
  return /** @type{!Array<!proto.TimerActive>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TimerActive, 3));
};


/**
 * @param {!Array<!proto.TimerActive>} value
 * @return {!proto.DeviceSchedule} returns this
*/
proto.DeviceSchedule.prototype.setTimeractiveList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.TimerActive=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TimerActive}
 */
proto.DeviceSchedule.prototype.addTimeractive = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.TimerActive, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceSchedule} returns this
 */
proto.DeviceSchedule.prototype.clearTimeractiveList = function() {
  return this.setTimeractiveList([]);
};


/**
 * optional bool channel1 = 4;
 * @return {boolean}
 */
proto.DeviceSchedule.prototype.getChannel1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceSchedule} returns this
 */
proto.DeviceSchedule.prototype.setChannel1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool channel2 = 5;
 * @return {boolean}
 */
proto.DeviceSchedule.prototype.getChannel2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceSchedule} returns this
 */
proto.DeviceSchedule.prototype.setChannel2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool channel3 = 6;
 * @return {boolean}
 */
proto.DeviceSchedule.prototype.getChannel3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceSchedule} returns this
 */
proto.DeviceSchedule.prototype.setChannel3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LightStatusItem.prototype.toObject = function(opt_includeInstance) {
  return proto.LightStatusItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LightStatusItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LightStatusItem.toObject = function(includeInstance, msg) {
  var f, obj = {
dim: jspb.Message.getFieldWithDefault(msg, 1, 0),
state: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
sn: jspb.Message.getFieldWithDefault(msg, 3, ""),
add: jspb.Message.getFieldWithDefault(msg, 4, 0),
group: jspb.Message.getFieldWithDefault(msg, 5, 0),
power: jspb.Message.getFieldWithDefault(msg, 6, 0),
voltage: jspb.Message.getFieldWithDefault(msg, 7, 0),
current: jspb.Message.getFieldWithDefault(msg, 8, 0),
cosphi: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
brightness: jspb.Message.getFieldWithDefault(msg, 10, 0),
statusCode: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LightStatusItem}
 */
proto.LightStatusItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LightStatusItem;
  return proto.LightStatusItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LightStatusItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LightStatusItem}
 */
proto.LightStatusItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDim(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSn(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAdd(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroup(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPower(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVoltage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCosphi(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrightness(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatusCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LightStatusItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LightStatusItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LightStatusItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LightStatusItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDim();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getState();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdd();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGroup();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPower();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getVoltage();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getCurrent();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getCosphi();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getBrightness();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getStatusCode();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional uint32 dim = 1;
 * @return {number}
 */
proto.LightStatusItem.prototype.getDim = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setDim = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool state = 2;
 * @return {boolean}
 */
proto.LightStatusItem.prototype.getState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string sn = 3;
 * @return {string}
 */
proto.LightStatusItem.prototype.getSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setSn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 add = 4;
 * @return {number}
 */
proto.LightStatusItem.prototype.getAdd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setAdd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 group = 5;
 * @return {number}
 */
proto.LightStatusItem.prototype.getGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 power = 6;
 * @return {number}
 */
proto.LightStatusItem.prototype.getPower = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setPower = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 voltage = 7;
 * @return {number}
 */
proto.LightStatusItem.prototype.getVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setVoltage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 current = 8;
 * @return {number}
 */
proto.LightStatusItem.prototype.getCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional float cosphi = 9;
 * @return {number}
 */
proto.LightStatusItem.prototype.getCosphi = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setCosphi = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional uint32 brightness = 10;
 * @return {number}
 */
proto.LightStatusItem.prototype.getBrightness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setBrightness = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 status_code = 11;
 * @return {number}
 */
proto.LightStatusItem.prototype.getStatusCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.LightStatusItem} returns this
 */
proto.LightStatusItem.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LightItem.prototype.toObject = function(opt_includeInstance) {
  return proto.LightItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LightItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LightItem.toObject = function(includeInstance, msg) {
  var f, obj = {
sn: jspb.Message.getFieldWithDefault(msg, 1, ""),
parentsn: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LightItem}
 */
proto.LightItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LightItem;
  return proto.LightItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LightItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LightItem}
 */
proto.LightItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentsn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LightItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LightItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LightItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LightItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentsn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sn = 1;
 * @return {string}
 */
proto.LightItem.prototype.getSn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LightItem} returns this
 */
proto.LightItem.prototype.setSn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parentSn = 2;
 * @return {string}
 */
proto.LightItem.prototype.getParentsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.LightItem} returns this
 */
proto.LightItem.prototype.setParentsn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PowerState.prototype.toObject = function(opt_includeInstance) {
  return proto.PowerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PowerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PowerState.toObject = function(includeInstance, msg) {
  var f, obj = {
enableoutput1: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
enableoutput2: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
enableoutput3: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
on1: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
on2: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
on3: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
mode: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PowerState}
 */
proto.PowerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PowerState;
  return proto.PowerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PowerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PowerState}
 */
proto.PowerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableoutput1(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableoutput2(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableoutput3(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOn1(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOn2(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOn3(value);
      break;
    case 7:
      var value = /** @type {!proto.PowerMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PowerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PowerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PowerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PowerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnableoutput1();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getEnableoutput2();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getEnableoutput3();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOn1();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOn2();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOn3();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional bool enableOutput1 = 1;
 * @return {boolean}
 */
proto.PowerState.prototype.getEnableoutput1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PowerState} returns this
 */
proto.PowerState.prototype.setEnableoutput1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool enableOutput2 = 2;
 * @return {boolean}
 */
proto.PowerState.prototype.getEnableoutput2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PowerState} returns this
 */
proto.PowerState.prototype.setEnableoutput2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool enableOutput3 = 3;
 * @return {boolean}
 */
proto.PowerState.prototype.getEnableoutput3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PowerState} returns this
 */
proto.PowerState.prototype.setEnableoutput3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool on1 = 4;
 * @return {boolean}
 */
proto.PowerState.prototype.getOn1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PowerState} returns this
 */
proto.PowerState.prototype.setOn1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool on2 = 5;
 * @return {boolean}
 */
proto.PowerState.prototype.getOn2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PowerState} returns this
 */
proto.PowerState.prototype.setOn2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool on3 = 6;
 * @return {boolean}
 */
proto.PowerState.prototype.getOn3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PowerState} returns this
 */
proto.PowerState.prototype.setOn3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional PowerMode mode = 7;
 * @return {!proto.PowerMode}
 */
proto.PowerState.prototype.getMode = function() {
  return /** @type {!proto.PowerMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.PowerMode} value
 * @return {!proto.PowerState} returns this
 */
proto.PowerState.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceState.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceState.toObject = function(includeInstance, msg) {
  var f, obj = {
powerstate: (f = msg.getPowerstate()) && proto.PowerState.toObject(includeInstance, f),
dim: jspb.Message.getFieldWithDefault(msg, 2, 0),
activetime: (f = msg.getActivetime()) && proto.ActiveLamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceState}
 */
proto.DeviceState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceState;
  return proto.DeviceState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceState}
 */
proto.DeviceState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PowerState;
      reader.readMessage(value,proto.PowerState.deserializeBinaryFromReader);
      msg.setPowerstate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDim(value);
      break;
    case 3:
      var value = new proto.ActiveLamp;
      reader.readMessage(value,proto.ActiveLamp.deserializeBinaryFromReader);
      msg.setActivetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPowerstate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.PowerState.serializeBinaryToWriter
    );
  }
  f = message.getDim();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActivetime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ActiveLamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional PowerState powerState = 1;
 * @return {?proto.PowerState}
 */
proto.DeviceState.prototype.getPowerstate = function() {
  return /** @type{?proto.PowerState} */ (
    jspb.Message.getWrapperField(this, proto.PowerState, 1));
};


/**
 * @param {?proto.PowerState|undefined} value
 * @return {!proto.DeviceState} returns this
*/
proto.DeviceState.prototype.setPowerstate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.clearPowerstate = function() {
  return this.setPowerstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceState.prototype.hasPowerstate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 dim = 2;
 * @return {number}
 */
proto.DeviceState.prototype.getDim = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setDim = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ActiveLamp activeTime = 3;
 * @return {?proto.ActiveLamp}
 */
proto.DeviceState.prototype.getActivetime = function() {
  return /** @type{?proto.ActiveLamp} */ (
    jspb.Message.getWrapperField(this, proto.ActiveLamp, 3));
};


/**
 * @param {?proto.ActiveLamp|undefined} value
 * @return {!proto.DeviceState} returns this
*/
proto.DeviceState.prototype.setActivetime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.clearActivetime = function() {
  return this.setActivetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceState.prototype.hasActivetime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MeterData.prototype.toObject = function(opt_includeInstance) {
  return proto.MeterData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MeterData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MeterData.toObject = function(includeInstance, msg) {
  var f, obj = {
v1: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
v2: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
v3: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
i1: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
i2: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
i3: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
p1: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
p2: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
p3: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
a1: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
a2: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
a3: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
power: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
total: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MeterData}
 */
proto.MeterData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MeterData;
  return proto.MeterData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MeterData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MeterData}
 */
proto.MeterData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV2(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV3(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI1(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI2(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI3(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP1(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP2(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP3(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA1(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA2(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA3(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPower(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MeterData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MeterData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MeterData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MeterData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getV1();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getV2();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getV3();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getI1();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getI2();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getI3();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getP1();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getP2();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getP3();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getA1();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getA2();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getA3();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getPower();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
};


/**
 * optional double v1 = 1;
 * @return {number}
 */
proto.MeterData.prototype.getV1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setV1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double v2 = 2;
 * @return {number}
 */
proto.MeterData.prototype.getV2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setV2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double v3 = 3;
 * @return {number}
 */
proto.MeterData.prototype.getV3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setV3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double i1 = 4;
 * @return {number}
 */
proto.MeterData.prototype.getI1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setI1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double i2 = 5;
 * @return {number}
 */
proto.MeterData.prototype.getI2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setI2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double i3 = 6;
 * @return {number}
 */
proto.MeterData.prototype.getI3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setI3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double p1 = 7;
 * @return {number}
 */
proto.MeterData.prototype.getP1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setP1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double p2 = 8;
 * @return {number}
 */
proto.MeterData.prototype.getP2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setP2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double p3 = 9;
 * @return {number}
 */
proto.MeterData.prototype.getP3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setP3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double a1 = 10;
 * @return {number}
 */
proto.MeterData.prototype.getA1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setA1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double a2 = 11;
 * @return {number}
 */
proto.MeterData.prototype.getA2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setA2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double a3 = 12;
 * @return {number}
 */
proto.MeterData.prototype.getA3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setA3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double power = 13;
 * @return {number}
 */
proto.MeterData.prototype.getPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double total = 14;
 * @return {number}
 */
proto.MeterData.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterData} returns this
 */
proto.MeterData.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StatusCode.prototype.toObject = function(opt_includeInstance) {
  return proto.StatusCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StatusCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatusCode.toObject = function(includeInstance, msg) {
  var f, obj = {
message: jspb.Message.getFieldWithDefault(msg, 1, ""),
code: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatusCode}
 */
proto.StatusCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatusCode;
  return proto.StatusCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatusCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatusCode}
 */
proto.StatusCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatusCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StatusCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatusCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatusCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.StatusCode.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StatusCode} returns this
 */
proto.StatusCode.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 code = 2;
 * @return {number}
 */
proto.StatusCode.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatusCode} returns this
 */
proto.StatusCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.DeviceType = {
  CPU_BASIC_NO_DIM: 0,
  CPU_DIM_RF: 1
};

/**
 * @enum {number}
 */
proto.AccountRole = {
  NONE: 0,
  ADMIN: 1,
  OPERATOR: 2
};

/**
 * @enum {number}
 */
proto.ConnectionState = {
  CONNECTION_OFFLINE: 0,
  CONNECTION_ONLINE: 1
};

/**
 * @enum {number}
 */
proto.PowerMode = {
  OFF: 0,
  AUTO: 2,
  MANUAL: 1
};

goog.object.extend(exports, proto);
