import React from 'react';
import {Container, Col, Row, ListGroup, Button, Form} from "react-bootstrap";
import {BsFillBookmarkFill} from 'react-icons/bs';
import './style.css';

import avatar from '../../resources/admin-icon.jpg';
import MapSign from '../../resources/map-sign.png'
import imageMap from '../../resources/hue-map.png';
import {RiBaseStationFill, RiLayoutMasonryFill} from "react-icons/ri";
import {FiArrowDown} from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import { SimpleMap } from "./SimpleMap";
import {BiTargetLock} from "react-icons/bi";
import {HiLocationMarker} from "react-icons/hi";
import {AiTwotoneSetting} from "react-icons/ai";
import {ImSwitch, ImConnection} from "react-icons/im";
import { config } from "../Config";

const AddStationState = {
  NONE: 0,
  INPUT_STATION: 1,
  VERIFY_DEVICE: 2,
  SUBMIT: 3,
}

const AnyReactComponent = ({ text }) => <div style={{width: '4em', height: '4em', backgroundColor: 'red'}}>{text}</div>;

const MapSignComponent = () => <img src={MapSign}/>

function openWindow (userId, stationId, user) {
  window.api.send('asynchronous-message', 'createNewWindow', userId, stationId, user);
}

class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addStationState: AddStationState.NONE,
      isStationNameValid: true,
      isHardwareIdValid: true,
      hardwareId: '',
      stationName: '',
      confirmedDelete: false,
      mapCenter: {
        lat: 16.46975867671549,
        lng: 107.60077122508798
      },
      stationLat: 0,
      stationLong: 0,
      curStationLat: null,
      curStationLong: null,
      mapZoom: 17
    };
    this.onSelectStation = props.onSelectStation || null;
    this.onLogout = props.onLogout || null;
    this.user = props.user || "Trần Thanh Lộc";
    this.onCheckDevice = props.onCheckDevice || null;
    this.onAddStation = props.onAddStation || null;
    this.onGetCoordinate = props.onGetCoordinate || null;
    this.onManagement = props.onManagement || null;
    this.onControlLane = props.onControlLane || null;

    this.onClickStation = this.onClickStation.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.onClickAddStation = this.onClickAddStation.bind(this);
    this.onCancelAddStation = this.onCancelAddStation.bind(this);
    this.onClickTarget = this.onClickTarget.bind(this);
    this.onControl = this.onControl.bind(this);
    this.onClickMap = this.onClickMap.bind(this);
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps, nextContext) {
      this.setState(state => {
        state.stationLat = nextProps.originLat;
        state.stationLong = nextProps.originLong;
        return state;
      });
  }

  onClickStation(station) {
    if (process.env.REACT_APP_MODE === 'web') {
      if (this.onSelectStation) {
        this.onSelectStation(station.id);
      }
    } else {
      openWindow(this.props.rootUserId || null, station.id, this.props.user);
    }
  }

  onClickLogout() {
    if (this.onLogout) {
      this.onLogout();
    }
  }

  onClickAddStation() {
    this.setState(state => state.addStationState = AddStationState.INPUT_STATION);
  }

  onCancelAddStation() {
    this.setState(state => {
      state.addStationState = AddStationState.NONE;
      state.hardwareId = '';
      state.stationName = '';
      state.isHardwareIdValid = true;
      state.isStationNameValid = true;
      return state;
    });
  }

  onCheck() {
    console.log('station name ' + this.state.stationName + " hardwareId " + this.state.hardwareId);
    let isStationNameValid = true;
    let isHardwareIdValid = true;
    if (this.state.stationName === null || this.state.stationName === '') {
      isStationNameValid = false;
    }
    if (this.props.stationList != null) {
      this.props.stationList.forEach(station => {
        if (station.name === this.state.stationName) {
          isStationNameValid = false;
        }
      })
    }

    if (this.state.hardwareId === null || this.state.hardwareId === '') {
      isHardwareIdValid = false;
    }

    if (isHardwareIdValid === true && isStationNameValid === true) {
      this.setState(state => {
        state.addStationState = AddStationState.VERIFY_DEVICE;
        state.isStationNameValid = true;
        state.isHardwareIdValid = true;
        return state;
      });
      if (this.onCheckDevice !== null) {
        this.onCheckDevice(this.state.hardwareId);
      }
    } else {
      this.setState(state => {
        state.isStationNameValid = isStationNameValid;
        state.isHardwareIdValid = isHardwareIdValid;
        return state;
      })
    }
  }

  onControl(deviceId, isOn) {
    if (this.onControlLane !== null) {
      this.onControlLane(isOn, 0, deviceId);
    }
    /*console.log("change center " + lat + ", " + lng);
    this.setState(state => {
      state.stationLat = lat;
      state.stationLong = lng;
      return state;
    })*/
  }

  getStationList(stationList) {
    console.log(stationList);
    if (stationList == null || stationList.length === 0) {
      return <div/>
    }
    let outItem = [];
    stationList.forEach(station => {
      let item =
        <ListGroup.Item key={station.id} className='station-item-text' action>
          <Row>
            <Col xs={2}>
              <ImConnection color={station.isOnline ? 'green' : 'grey'} size={25}/>
            </Col>
            <Col xs={8} style={{
              textAlign: 'left'
            }} onClick={() => this.onClickStation(station)}>
              <Row>
                <Col>
                  <p className='station-name'>
                    {station?.name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{padding: "0px", margin: "0px", fontSize: "0.8em"}}>
                    {station.hardwareId}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={2} className='btn-jump-point' style={{padding: '0px'}}>
              <Button variant={station.isOn ? 'primary' : 'outline-secondary'}
                      disabled={true}
              >
                <ImSwitch className='icon-style'/>
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
      outItem.push(item);
    });
    return (
      <div>
        <ListGroup variant="flush" style={{
          textAlign: 'center'
        }}>
          {outItem}
        </ListGroup>
      </div>
    )
  }

  onChange(type, value) {
    if (type === 'hardwareId') {
      this.setState(state => {
        state.hardwareId = value;
        state.addStationState = AddStationState.INPUT_STATION
        return state;
      });
    } else if (type === 'stationName') {
      this.setState(state => {
        state.stationName = value;
        state.addStationState = AddStationState.INPUT_STATION;
        return state;
      });
    }
  }

  onSubmitAddStation(stationName, hardwareId, stationLat, stationLong) {
    if (this.onAddStation !== null) {
      this.onAddStation(stationName, hardwareId, stationLat, stationLong);
    }
    this.setState(state => state.addStationState = AddStationState.NONE);
  }

  onClickTarget() {
    console.log("on click target");
    if (this.onGetCoordinate) {
      this.onGetCoordinate(this.state.hardwareId);
    }
    /*this.setState(state => state.mapCenter = {
      lat: 16.3,
      lng: 107.60077122508798
    });*/
  }

  addDeviceFormEnterStation(addStationResult) {
    console.log(addStationResult);
    return (
      <Form style={{width: '100%'}}>
        <Form.Group controlId="stationName">
          <Form.Label style={{color: 'black'}}>Tên trạm:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ngã tư..."
            required
            isInvalid={!this.state.isStationNameValid}
            onChange={(event) => this.onChange('stationName', event.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="stationName">
          <Form.Label style={{color: 'black'}}>Mã thiết bị:</Form.Label>
          <Form.Row>
            <Col>
              <Form.Control
                type="text"
                placeholder="GW-12:34:56:78"
                required
                isInvalid={!this.state.isHardwareIdValid}
                onChange={(event) => this.onChange('hardwareId', event.target.value)}
              />
            </Col>
          </Form.Row>
        </Form.Group>

        {this.state.addStationState === AddStationState.VERIFY_DEVICE &&
        <div>
          <Container style={{textAlign: 'center', paddingBottom: '0.8em'}}>
            <FiArrowDown size={20}/>
          </Container>

          {addStationResult.status !== null &&
            <Form.Row controlId="stationName">
              <Col xs={4}>
                <Form.Label style={{color: 'black'}}>Tình trạng:</Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control readOnly type="text" placeholder={addStationResult.status}/>
              </Col>
            </Form.Row>
          }

          {addStationResult.cpuType !== null &&
            <Form.Row controlId="stationName">
              <Col xs={4}>
                <Form.Label style={{color: 'black'}}>Loại CPU:</Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control readOnly type="text" placeholder={addStationResult.cpuType}/>
              </Col>
            </Form.Row>
          }

          <Form.Group>
            <Form.Label style={{color: 'black'}}>Tọa độ (lat/long):</Form.Label>
            <Form.Row>
              {/*<Col xs={2}>*/}
              {/*  <Form.Label style={{color: 'black', float: 'right'}}>Lat/Long:</Form.Label>*/}
              {/*</Col>*/}
              <Col xs={5}>
                <Form.Control readOnly type='text' placeholder={'0.0000'} value={this.state.stationLat?.toFixed(6)}/>
              </Col>
              {/*<Col xs={1}>*/}
              {/*  <Form.Label style={{color: 'black', float: 'right'}}>Long</Form.Label>*/}
              {/*</Col>*/}
              <Col xs={5}>
                <Form.Control readOnly type='text' placeholder={'0.0000'} value={this.state.stationLong?.toFixed(6)}/>
              </Col>
              <Col xs={2}>
                <Button
                  style={{float: 'right'}}
                  onClick={() => this.onClickTarget()}
                >
                  <BiTargetLock size={22}/>
                </Button>
              </Col>
              <Form.Text style={{fontSize: '14px'}}>*Note: Click trực tiếp vào bản đồ để lựa chọn tọa độ khác</Form.Text>
            </Form.Row>
          </Form.Group>

          {addStationResult.stationName !== null &&
            <div>
            <Form.Group>
              <Form.Label style={{color: 'black'}}>Trạm hiện tại:</Form.Label>
              <Form.Control type="text" placeholder={addStationResult.stationName} readOnly/>
            </Form.Group>
              <Form.Group>
              <Form.Check
                type="checkbox"
                label={"Xác nhận xóa trạm " + addStationResult.stationName}
                checked={this.state.confirmedDelete}
                onChange={(event) => {this.setState(state => state.confirmedDelete = event.target.checked)}}
              />
              </Form.Group>
            </div>
          }
        </div>
        }

        <Row style={{paddingTop: '1.5em'}}>
          <Col style={{float: 'right'}}>
            <Button
              style={{float: 'right', width: '6em'}}
              variant='danger'
              onClick={() => this.onCancelAddStation()}
            >
              Hủy
            </Button>
          </Col>
          <Col style={{float: 'left'}}>
            {this.state.addStationState === AddStationState.INPUT_STATION ?
              <Button
                variant='info'
                style={{float: 'left', width: '6em'}}
                onClick={() => {this.onCheck()}}
              >
                Kiểm tra
              </Button>
              :
              <Button
                style={{float: 'left', width: '6em'}}
                variant='primary'
                disabled={addStationResult.stationName !== null && this.state.confirmedDelete === false}
                onClick={() => this.onSubmitAddStation(
                  this.state.stationName,
                  this.state.hardwareId,
                  this.state.stationLat,
                  this.state.stationLong
                )}
              >
                Xác nhận
              </Button>
            }
          </Col>
        </Row>
      </Form>
    )
  }

  onClickMap(event) {
    console.log("onclick map " + event.lat + ", " + event.lng);
    if (this.state.addStationState === AddStationState.VERIFY_DEVICE) {
      this.setState(state => {
        state.stationLat = event.lat;
        state.stationLong = event.lng;
        return state;
      })
    }
  }

  stationMaker(lat, lng, label, station) {
    let height = 117;
    let width = 38;
    const styleMaker = {
      position: 'absolute',
      left: (-width / 2).toString() + 'px',
      top: (-height).toString() + 'px'
    }

    const styleLabel = {
      position: 'absolute',
      width: (2 * width).toString() + 'px',
      left: (-width).toString() + 'px',
      bottom: (5 + height).toString() + 'px',
      textAlign: 'center',
      fontSize: '17px',
      backgroundColor: 'rgba(23,162,184,0.73)',
      padding: '0.2em 0em',
      borderRadius: '6px',
    }

    return (
      <div lat={lat}
           lng={lng}
           onClick={() => this.onClickStation(station)}
      >
      <img
        width={width}
        height={height}
        style={styleMaker}
        src={MapSign}/>
        <div style={styleLabel}>
          {label}
        </div>
      </div>
    )
  }

  stationMakerList(stations) {
    let retList = [];
    stations.forEach(station => {
      if (station === null || station.lat === undefined || station.log === undefined) {
        return;
      }
      retList.push(this.stationMaker(station.lat, station.log, station.name, station));
    });
    return (
      // <div>
      retList
      // </div>
    )
  }

  onClickManagement() {
    if (this.onManagement !== null) {
      this.onManagement();
    }
  }

  render() {
    return (
      <Container fluid className='container-page'>
        <Row className='row-main'>
          <Col className='main-left'>
            <Container className='user'>
              <Row>
                <Col xs={2}>
                  <img src={avatar} className='user-icon'/>
                </Col>
                <Col xs={7} style={{margin: 'auto'}}>
                  <p className='user-name'>
                    {this.user}
                  </p>
                </Col>
                <Col xs={3} style={{margin: 'auto'}}>
                  <Button variant="outline-danger" style={{
                    border: "2px solid #d4d4d4",
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    padding: '0.5em',
                    margin: 'auto'
                  }} onClick={() => this.onClickLogout()}>
                    <p style={{
                      color: '#d4d4d4',
                      margin: 'auto',
                      textAlign: 'center'
                    }}>Thoát</p>
                  </Button>
                </Col>
              </Row>
            </Container>
            <Container className='station-list'>
              <Row className='title-wrapper'>
                <div className='station-title'>
                  <p className='station-title-text'>
                    Danh sách các trạm
                  </p>
                </div>
              </Row>
              <Row style={{margin: 'auto'}}>
                <Col>
                  {this.getStationList(this.props.stationList)}
                </Col>
              </Row>
              {this.state.addStationState === AddStationState.NONE &&
              <Row className='add-station-button'>
                <Col style={{
                  // height: '10em',
                  padding: '5em 0em 3em 0em',
                }}>
                  <div style={{
                    margin: '0',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}>
                    <Button style={{
                      borderRadius: '9px',
                      // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                      backgroundColor: 'black'
                    }} onClick={() => {
                      this.onClickAddStation()
                    }}
                     disabled={this.props.role !== 'ADMIN'}
                    >
                      <p style={{
                        margin: '0',
                        padding: '0',
                        fontWeight: '500',
                        fontSize: '1.1em',
                      }}>
                        + Thêm trạm
                      </p>
                    </Button>
                  </div>
                </Col>
              </Row>
              }
              {this.state.addStationState !== AddStationState.NONE &&
              <Container>
                <Row style={{
                  color: 'black',
                  padding: '1.5em 1em',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  marginTop: '2em'
                }}>
                  <Col>
                    <Row className='add-station'>
                      <RiBaseStationFill size={25}/>
                      <p className='add-station-title-text'>
                        Thêm trạm
                      </p>
                    </Row>
                    <Row style={{paddingRight: '1em', paddingLeft: '1em'}}>
                      {this.addDeviceFormEnterStation(this.props.addStationResult)}
                    </Row>
                  </Col>
                </Row>
              </Container>
              }
            </Container>
            <Row className='btn-management-wrapper'>
              <Col style={{
                padding: '2em',
                textAlign: 'center'
              }}>
                <Button className='btn-management'
                  variant='warning'
                  onClick={() => this.onClickManagement()}
                >
                  <AiTwotoneSetting className='icon-style'/> Quản trị
                </Button>
              </Col>
            </Row>
          </Col>
          <Col className='main-right'>

          </Col>
        </Row>
      </Container>
    )
  }
}

export default MapPage;